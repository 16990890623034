import { apiBasePath } from "@/config";
import { showApiErrorResponse } from "@/notify";
import axios from "axios";
import BN from "bn.js";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from ".";

export interface ChainRelatedPackageDetails {
  minimumSnipeAmount: BN;
  maximumFeeOwed: BN;
  price: BN;
}

export interface Package {
  id: number;
  name: string;
  maximumParallelSnipes: number;
  maximumSnipeHistoryLength: number;
  maximumAllowedAccounts: number;
  maximumNumberOfPresets: number;
  accountImportAllowed: boolean;
  buyCommission: number;
  sellCommission: number;

  chainRelated: Record<string, ChainRelatedPackageDetails>;

  minimumSnipeAmount: BN;
  maximumFeeOwed: BN;
}

export interface PackageState {
  items: Package[];
}

const packageState: PackageState = {
  items: [],
};

const packageGetters: GetterTree<PackageState, RootState> = {
  items(state, _getters, rootState) {
    if (!rootState.chain) {
      return [];
    }

    return state.items.map((p) => ({
      ...p,
      ...p.chainRelated[rootState.chain as string],
    }));
  },
  userPackage(_state, getters, rootState): Package {
    return getters.items.find((p: Package) => p.id === rootState.userPackageId);
  },
};

const packageMutations: MutationTree<PackageState> = {
  setPackages(state, payload: Package[]) {
    state.items = payload;
  },
};

const packageActions: ActionTree<PackageState, RootState> = {
  read({ commit }) {
    axios
      .get(apiBasePath + "/package")
      .then((response) => {
        commit("setPackages", response.data);
      })
      .catch((error) => {
        showApiErrorResponse(error);
      });
  },
};

const packageModule: Module<PackageState, RootState> = {
  namespaced: true,
  state: packageState,
  getters: packageGetters,
  mutations: packageMutations,
  actions: packageActions,
};

export default packageModule;
