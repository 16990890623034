import { apiBasePath } from "@/config";
import { showApiErrorResponse, showSuccess } from "@/notify";
import axios from "axios";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from ".";

export interface Preset {
  id: string;
  name: string;
  configuration: any;
}

export interface PresetState {
  items: Preset[];
}

const presetState: PresetState = {
  items: [],
};

const presetGetters: GetterTree<PresetState, RootState> = {
  itemsOnChain(state, _getters, rootState): Preset[] {
    if (!rootState.chain) {
      return [];
    }

    return state.items.filter(
      (item) => item.configuration.chain === rootState.chain
    );
  },
};

const presetMutations: MutationTree<PresetState> = {
  setPresets(state, payload: Preset[]) {
    state.items = payload;
  },
  addPreset(state, payload: Preset) {
    state.items.push(payload);
  },
  updatePreset(state, payload: Preset) {
    const presetIndex = state.items.findIndex((p) => p.id === payload.id);
    if (presetIndex >= 0) {
      state.items[presetIndex] = { ...payload };
    }
  },
  deletePreset(state, payload: string) {
    state.items = state.items.filter((p) => p.id !== payload);
  },
};

const presetActions: ActionTree<PresetState, RootState> = {
  read({ commit }) {
    axios
      .get(apiBasePath + "/preset")
      .then((response) => {
        commit("setPresets", response.data);
      })
      .catch((error) => {
        showApiErrorResponse(error);
      });
  },
  async create({ commit }, payload: Preset) {
    try {
      const response = await axios.post(apiBasePath + "/preset", payload);
      commit("addPreset", {
        ...payload,
        id: response.data,
      });
      showSuccess("Preset was successfully created");

      return true;
    } catch (error) {
      showApiErrorResponse(error);
    }
  },
  update({ commit }, payload: Preset) {
    axios
      .patch(apiBasePath + "/preset/" + payload.id, {
        name: payload.name,
        configuration: payload.configuration,
      })
      .then(() => {
        commit("updatePreset", payload);
        showSuccess("Preset was successfully updated");
      })
      .catch((error) => {
        showApiErrorResponse(error);
      });
  },
  delete({ commit }, payload: string) {
    axios
      .delete(apiBasePath + "/preset/" + payload)
      .then(() => {
        commit("deletePreset", payload);
        showSuccess("Preset was successfully deleted");
      })
      .catch((error) => {
        showApiErrorResponse(error);
      });
  },
};

const presetModule: Module<PresetState, RootState> = {
  namespaced: true,
  state: presetState,
  getters: presetGetters,
  mutations: presetMutations,
  actions: presetActions,
};

export default presetModule;
