import { createApp } from "vue";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import { darkModeKey, styleKey } from "@/config.js";
import Notifications from "@kyvg/vue3-notification";
import { showWarning, showError } from "@/notify";

import "./css/main.css";

/* App style */
store.dispatch("setStyle", localStorage[styleKey] ?? "basic");

const handleTokens = () => {
  if (!store.state.auth.loggedIn) {
    return;
  }

  // @ts-ignore
  if (store.state.auth.refreshTokenExpiresAt <= Date.now() / 1000) {
    showError("Token expired");
    store.dispatch("auth/logOut");
  } else if (
    // @ts-ignore
    store.state.auth.refreshTokenExpiresAt <=
    Date.now() / 1000 + 5 * 60
  ) {
    const minutesLeft =
      // @ts-ignore
      (store.state.auth.refreshTokenExpiresAt - Date.now() / 1000) / 60;
    showWarning(
      `Token will expire in ${Math.round(
        minutesLeft
      )} minutes, please log in again`
    );
  } else if (
    // @ts-ignore
    (store.state.auth.accessTokenExpiresAt - Date.now() / 1000) / 60 <
    10
  ) {
    store.dispatch("auth/refreshAccessToken");
  }
};

setInterval(handleTokens, 60 * 1000);

const storedChains = localStorage.getItem("chains");
if (storedChains !== null) {
  const chainDetails: Record<string, any> = JSON.parse(storedChains);
  if (chainDetails) {
    store.commit("basic", { key: "chainDetails", value: chainDetails });

    const storedChain = localStorage.getItem("chain");
    if (storedChain) {
      store.commit("setChain", storedChain);
    } else {
      store.commit("setChain", Object.values(chainDetails)[0].key);
    }
  }
}

const storedTokenInfo = localStorage.getItem("tokenInfo");
if (storedTokenInfo !== null) {
  const tokenInfo = JSON.parse(storedTokenInfo);
  if (tokenInfo) {
    store.commit("basic", { key: "tokenInfo", value: tokenInfo });
  }
}

/* Dark mode */
if (localStorage[darkModeKey] === "0") {
  store.dispatch("darkMode", false);
} else {
  store.dispatch("darkMode", true);
}

/* Default title tag */
const defaultDocumentTitle = "Shuutr";

/* Collapse mobile aside menu on route change */
router.beforeEach(() => {
  store.dispatch("asideMobileToggle", false);
  store.dispatch("asideLgToggle", false);
});

router.afterEach((to) => {
  /* Set document title from route meta */
  if (to.meta && to.meta.title) {
    document.title = `${to.meta.title} — ${defaultDocumentTitle}`;
  } else {
    document.title = defaultDocumentTitle;
  }

  /* Full screen mode */
  store.dispatch("fullScreenToggle", !!to.meta.fullScreen);
});

/* Auth */
store.dispatch("auth/loadStoredCredentials").then(() => {
  createApp(App).use(store).use(router).use(Notifications).mount("#app");
});
