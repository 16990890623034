import { createRouter, createWebHashHistory } from "vue-router";
import Error from "@/views/Error.vue";
import store from "../store";

const routes = [
  // XXX landing {
  // XXX landing   meta: {
  // XXX landing     title: "Shuutr",
  // XXX landing     fullScreen: true,
  // XXX landing   },
  // XXX landing   path: "/",
  // XXX landing   name: "landing",
  // XXX landing   component: () => import("@/views/Landing.vue"),
  // XXX landing },
  {
    path: "/",
    redirect: () => {
      return { path: "/dashboard" };
    },
  },
  {
    meta: {
      title: "Dashboard",
    },
    path: "/dashboard",
    name: "dashboard",
    component: () => import("@/views/Dashboard.vue"),
  },
  {
    meta: {
      title: "Login",
      fullScreen: true,
    },
    path: "/login",
    name: "login",
    component: () => import("@/views/Login.vue"),
  },
  {
    meta: {
      title: "Register",
      fullScreen: true,
    },
    path: "/register",
    name: "register",
    component: () => import("@/views/Register.vue"),
  },
  {
    meta: {
      title: "Profile",
    },
    path: "/profile",
    name: "profile",
    component: () => import("@/views/Profile.vue"),
  },
  {
    meta: {
      title: "Snipe list",
    },
    path: "/snipe/list",
    name: "snipeList",
    component: () => import("@/views/SnipeList.vue"),
  },
  {
    meta: {
      title: "Create snipe",
    },
    path: "/snipe/create",
    name: "createSnipe",
    component: () => import("@/views/CreateSnipe.vue"),
  },
  {
    meta: {
      title: "Snipe details",
    },
    path: "/snipe/:id",
    name: "snipeDetails",
    component: () => import("@/views/SnipeDetails.vue"),
  },
  {
    meta: {
      title: "Manual sell",
    },
    path: "/snipe/:id/sell",
    name: "manualSnipeSell",
    component: () => import("@/views/ManualSellSnipe.vue"),
  },
  {
    meta: {
      title: "Copy snipe",
    },
    path: "/snipe/:id/copy",
    name: "copySnipe",
    component: () => import("@/views/CopySnipe.vue"),
  },
  {
    meta: {
      title: "Accounts",
    },
    path: "/accounts",
    name: "accounts",
    component: () => import("@/views/Accounts.vue"),
  },
  {
    meta: {
      title: "Account details",
    },
    path: "/accounts/:publicKey",
    name: "accountDetails",
    component: () => import("@/views/AccountDetails.vue"),
  },
  {
    meta: {
      title: "Packages",
    },
    path: "/packages",
    name: "packages",
    component: () => import("@/views/Packages.vue"),
  },
  {
    meta: {
      title: "Presets",
    },
    path: "/presets/list",
    name: "presetList",
    component: () => import("@/views/PresetList.vue"),
  },
  {
    meta: {
      title: "Create preset",
    },
    path: "/presets/create",
    name: "createPreset",
    component: () => import("@/views/CreatePreset.vue"),
  },
  {
    meta: {
      title: "Edit preset",
    },
    path: "/presets/:name/edit",
    name: "editPreset",
    component: () => import("@/views/EditPreset.vue"),
  },
  {
    meta: {
      title: "Preset details",
    },
    path: "/presets/:name",
    name: "presetDetails",
    component: () => import("@/views/PresetDetails.vue"),
  },
  {
    meta: {
      title: "Fee list",
    },
    path: "/fees",
    name: "feeList",
    component: () => import("@/views/FeeList.vue"),
  },
  {
    meta: {
      title: "General documentation",
    },
    path: "/docs/general",
    name: "generalDocs",
    component: () => import("@/views/documentation/General.vue"),
  },
  {
    meta: {
      title: "Authentication documentation",
    },
    path: "/docs/authentication",
    name: "authenticationDocs",
    component: () => import("@/views/documentation/Authentication.vue"),
  },
  {
    meta: {
      title: "Account management documentation",
    },
    path: "/docs/account",
    name: "accountDocs",
    component: () => import("@/views/documentation/Account.vue"),
  },
  {
    meta: {
      title: "Preset documentation",
    },
    path: "/docs/preset",
    name: "presetDocs",
    component: () => import("@/views/documentation/Preset.vue"),
  },
  {
    meta: {
      title: "Sniping documentation",
    },
    path: "/docs/snipe",
    name: "snipeDocs",
    component: () => import("@/views/documentation/Snipe.vue"),
  },
  {
    meta: {
      title: "Deposit documentation",
    },
    path: "/docs/deposit",
    name: "depositDocs",
    component: () => import("@/views/documentation/Deposit.vue"),
  },
  {
    meta: {
      title: "Withdrawal documentation",
    },
    path: "/docs/withdrawal",
    name: "withdrawalDocs",
    component: () => import("@/views/documentation/Withdrawal.vue"),
  },
  {
    meta: {
      title: "Package documentation",
    },
    path: "/docs/package",
    name: "packageDocs",
    component: () => import("@/views/documentation/Package.vue"),
  },
  {
    meta: {
      title: "Fee documentation",
    },
    path: "/docs/fee",
    name: "feeDocs",
    component: () => import("@/views/documentation/Fee.vue"),
  },
  {
    meta: {
      title: "Not found",
    },
    path: "/:pathMatch(.*)*",
    name: "notFound",
    component: Error,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  if (
    store.state.auth.initialValidationOngoing ||
    store.state.auth.loggedIn ||
    to.name === "login" ||
    to.name === "register" /* XXX Landing ||
    to.name === "landing" */
  ) {
    next();
  } else {
    next({ name: "login" });
  }
});

export default router;
