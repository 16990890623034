import {
  mdiDesktopMac,
  mdiWallet,
  mdiCrosshairs,
  mdiPackage,
  mdiTune,
  mdiCurrencyUsdOff,
  mdiFileDocument,
} from "@mdi/js";

export default [
  "General",
  [
    {
      to: "/dashboard",
      icon: mdiDesktopMac,
      label: "Dashboard",
    },
    {
      to: "/accounts",
      icon: mdiWallet,
      label: "Accounts",
    },
    {
      label: "Snipe",
      icon: mdiCrosshairs,
      to: "/snipe/list",
    },
    {
      label: "Presets",
      icon: mdiTune,
      to: "/presets/list",
    },
    {
      to: "/fees",
      label: "Fees",
      icon: mdiCurrencyUsdOff,
    },
    {
      to: "/packages",
      label: "Packages",
      icon: mdiPackage,
    },
    {
      label: "User guide",
      icon: mdiFileDocument,
      menu: [
        {
          label: "General",
          to: "/docs/general",
        },
        {
          label: "Authentication",
          to: "/docs/authentication",
        },
        {
          label: "Account management",
          to: "/docs/account",
        },
        {
          label: "Presets",
          to: "/docs/preset",
        },
        {
          label: "Sniping",
          to: "/docs/snipe",
        },
        {
          label: "Deposit",
          to: "/docs/deposit",
        },
        {
          label: "Withdrawal",
          to: "/docs/withdrawal",
        },
        {
          label: "Packages",
          to: "/docs/package",
        },
        {
          label: "Fees",
          to: "/docs/fee",
        },
      ],
    },
  ],
];
