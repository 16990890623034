<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import menu from "@/menu";
import NavBar from "@/components/NavBar.vue";
import AsideMenu from "@/components/AsideMenu.vue";
import Overlay from "@/components/Overlay.vue";

const store = useStore();

const isAsideLgActive = computed(() => store.state.isAsideLgActive);

const overlayClick = () => {
  store.dispatch("asideLgToggle", false);
};
</script>

<template>
  <notifications position="top right" class="mt-3 mr-3" />
  <nav-bar />
  <aside-menu :menu="menu" />
  <router-view />
  <overlay
    v-show="isAsideLgActive"
    z-index="z-30"
    @overlay-click="overlayClick"
  />
</template>
