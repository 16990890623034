import BN from "bn.js";
import { showError, showSuccess } from "./notify";
import store from "@/store";
import transactionDefaults from "@/json/defaults/transactionConfig.json";

export const TriggerTypes = [
  {
    id: "priceChange",
    label: "Price change",
  },
  {
    id: "priceChangeTriggeredTakeProfit",
    label: "Price change triggered take profit",
  },
  {
    id: "swapContractCreation",
    label: "Swap contract creation",
  },
  {
    id: "tradingEnabled",
    label: "Trading enabled flag",
  },
  {
    id: "timeout",
    label: "Timeout",
  },
];

export const PairCheckerTypes = [
  {
    id: "blockExplorerSourceChecker",
    label: "Block explorer source checker",
  },
  {
    id: "honeyPotIsChecker",
    label: "honeypot.is checker",
  },
];

export function copy<T>(inputObject: T): T {
  // Prevent undefined objects
  if (!inputObject) return inputObject;

  const outputObject: any = Array.isArray(inputObject) ? [] : {};

  let value;
  for (const key in inputObject) {
    // Prevent self-references to parent object
    if (Object.is(inputObject[key], inputObject)) continue;

    value = inputObject[key];

    outputObject[key as any] = typeof value === "object" ? copy(value) : value;
  }

  return outputObject as T;
}

export function amountInBnToNumber(bn: BN) {
  const divider = new BN(10).pow(new BN(12));
  const downScaled = bn.div(divider);
  return downScaled.toNumber() / 10e5;
}

export function camelCaseToSentenceCase(string: string) {
  const result = string.replace(/([A-Z])/g, " $1");
  const final = result.charAt(0).toUpperCase() + result.slice(1);
  return final;
}

export function copyToClipboard(text: string) {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      showSuccess("Successfully copied to clipboard");
    })
    .catch(() => {
      showError("Could not copy to clipboard");
    });
}

export function getAccountName(publicKey: string) {
  const account = store.state.accounts.find(
    (account) => account.publicKey === publicKey
  );

  if (!account) {
    return undefined;
  }

  return account.name;
}

export function baseTokenBalanceToFloat(balance: string) {
  return parseFloat(balance) / 1e18;
}

export function weiBalanceToFloat(balance: any) {
  const tokenInfo = getTokenInfo(balance.tokenAddress);
  if (tokenInfo === undefined) {
    return parseFloat(balance.balance) / 1e18;
  }

  return parseFloat(balance.balance) / Math.pow(10, tokenInfo.decimals);
}

function getTokenInfo(address: string) {
  if (store.state.chain && store.state.chain in store.state.tokenInfo) {
    return store.state.tokenInfo[store.state.chain][address];
  }
}

export function baseAmountToFloat(amount: string) {
  const tokenInfo = getTokenInfo(store.getters.chainDetails.baseTokenAddress);

  if (tokenInfo === undefined) {
    return parseFloat(amount) / 1e18;
  }

  return parseFloat(amount) / Math.pow(10, tokenInfo.decimals);
}

export function amountToFloat(address: string | undefined, amount: string) {
  if (!address) {
    return parseFloat(amount) / 1e18;
  }

  const tokenInfo = getTokenInfo(address);

  if (tokenInfo === undefined) {
    return parseFloat(amount) / 1e18;
  }

  return parseFloat(amount) / Math.pow(10, tokenInfo.decimals);
}

export function getTokenName(address: string) {
  if (!address) {
    return undefined;
  }

  const tokenInfo = getTokenInfo(address);
  if (!tokenInfo) {
    return undefined;
  }

  return tokenInfo.name;
}

export function getTokenSymbol(address: string) {
  if (!address) {
    return undefined;
  }

  const tokenInfo = getTokenInfo(address);
  if (!tokenInfo) {
    return undefined;
  }

  return tokenInfo.symbol;
}

export function getTokenString(address: string) {
  if (!address) {
    return store.getters.chainDetails.baseTokenName;
  }

  const tokenInfo = getTokenInfo(address);
  if (!tokenInfo) {
    return address;
  }

  if (tokenInfo.name) {
    return tokenInfo.name;
  }

  if (tokenInfo.symbol) {
    return tokenInfo.symbol;
  }

  return address;
}

export function getPrice(token: string, swap: string) {
  if (
    !token ||
    token === store.getters.chainDetails.baseTokenAddress ||
    token === store.getters.chainDetails.baseTokenName
  ) {
    return 1;
  }

  if (!store.state.chain || !(store.state.chain in store.state.prices)) {
    return undefined;
  }

  const swapToUse = swap ?? store.getters.chainDetails.defaultSwap;
  if (!(swapToUse in store.state.prices[store.state.chain])) {
    return undefined;
  }

  return store.state.prices[store.state.chain][swapToUse][token];
}

export const calculateProfit = (snipe: any) => {
  if (!snipe.trades || !snipe.trades.length) {
    return NaN;
  }

  let buyAmount = new BN(0);
  let sellAmount = new BN(0);

  snipe.trades.forEach((trade: any) => {
    if (trade.buyTransaction) {
      buyAmount = buyAmount.add(new BN(trade.buyTransaction.inAmount));
    }

    if (trade.sellTransaction) {
      sellAmount = sellAmount.add(new BN(trade.sellTransaction.outAmount));
    }
  });

  if (sellAmount.eq(new BN(0))) {
    return NaN;
  }

  return (
    (amountToFloat(snipe.config.pair.from, sellAmount.toString()) /
      amountToFloat(snipe.config.pair.from, buyAmount.toString())) *
    100
  );
};

export const getTransactionDefaults = () => {
  return {
    ...{
      buy: { ...transactionDefaults.buy },
      sell: { ...transactionDefaults.sell },
    },
  };
};
