import { notify } from "@kyvg/vue3-notification";

export function showError(message) {
  console.error(message);
  notify({
    text: message,
    type: "error",
  });
}

export function showWarning(message) {
  console.warn(message);
  notify({
    text: message,
    type: "warn",
  });
}

export function showSuccess(message) {
  notify({
    text: message,
    type: "success",
  });
}

export function showInfo(message) {
  notify({
    text: message,
  });
}

export function showApiErrorResponse(error) {
  try {
    for (const message of error.response.data.errors) {
      showError(message);
    }
  } catch (e) {
    showError(error.message);
  }
}
