import { apiBasePath } from "@/config";
import { showApiErrorResponse, showSuccess } from "@/notify";
import { baseAmountToFloat } from "@/utils";
import axios from "axios";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from ".";
import { Package } from "./packages";

export interface Fee {
  id: number;
  createdAt: Date;
  type: number;
  paid: boolean;
}

export interface Commission extends Fee {
  amount: number;
  typeName: string;
  chain: string;
}

export interface CommissionJson extends Fee {
  amount: string;
  typeName: string;
  chain: string;
}

export interface PackageFee extends Fee {
  packageId: number;
}

interface FeeDeposit {
  transactionId: string;
  chain: string;
  amount: number;
  availableAmount: number;
  createdAt: Date;
  updatedAt: Date;
}

interface FeeDepositJson {
  transactionId: string;
  chain: string;
  amount: string;
  availableAmount: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface FeeWithdrawal {
  id: number;
  transactionId?: string;
  chain: string;
  amount: number;
  state: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface FeeWithdrawalJson {
  id: number;
  transactionId?: string;
  chain: string;
  amount: string;
  state: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface FeeState {
  commissions: Record<string, Commission[]>;
  packageFees: PackageFee[];
  deposits: Record<string, FeeDeposit[]>;
  withdrawals: Record<string, FeeWithdrawal[]>;
}

const feeState: FeeState = {
  commissions: {},
  packageFees: [],
  deposits: {},
  withdrawals: {},
};

const feeGetters: GetterTree<FeeState, RootState> = {
  commissions(state, _getters, rootState): Commission[] {
    if (rootState.chain && rootState.chain in state.commissions) {
      return state.commissions[rootState.chain];
    }

    return [];
  },

  deposits(state, _getters, rootState): FeeDeposit[] {
    if (rootState.chain && rootState.chain in state.deposits) {
      return state.deposits[rootState.chain];
    }

    return [];
  },

  withdrawals(state, _getters, rootState): FeeWithdrawal[] {
    if (rootState.chain && rootState.chain in state.withdrawals) {
      return state.withdrawals[rootState.chain];
    }

    return [];
  },

  debt(state, _getters, rootState, rootGetters): number {
    let debt = 0;

    if (rootState.chain) {
      if (rootState.chain in state.commissions) {
        const commissions = state.commissions[rootState.chain];
        for (const commission of commissions) {
          if (!commission.paid) {
            debt += commission.amount;
          }
        }
      }

      for (const packageFee of state.packageFees) {
        if (!packageFee.paid) {
          debt += baseAmountToFloat(
            rootGetters["packages/items"].find(
              (p: Package) => p.id === packageFee.packageId
            ).price
          );
        }
      }
    }

    return debt;
  },
};

const feeMutations: MutationTree<FeeState> = {
  setFees(
    state,
    payload: {
      commissions: Record<string, CommissionJson[]>;
      packageFees: PackageFee[];
      deposits: Record<string, FeeDepositJson[]>;
      withdrawals: Record<string, FeeWithdrawalJson[]>;
    }
  ) {
    for (const [chain, fees] of Object.entries(payload.commissions)) {
      state.commissions[chain] = fees.map((feeEntry) => {
        const result = {
          ...feeEntry,
          amount: baseAmountToFloat(feeEntry.amount),
        };

        return result;
      });
    }

    state.packageFees = payload.packageFees;

    for (const [chain, deposits] of Object.entries(payload.deposits)) {
      state.deposits[chain] = deposits.map((deposit) => {
        const result = {
          ...deposit,
          amount: baseAmountToFloat(deposit.amount),
          availableAmount: baseAmountToFloat(deposit.availableAmount),
        };

        return result;
      });
    }

    for (const [chain, withdrawals] of Object.entries(payload.withdrawals)) {
      state.withdrawals[chain] = withdrawals.map((withdrawal) => {
        const result = {
          ...withdrawal,
          amount: baseAmountToFloat(withdrawal.amount),
        };

        return result;
      });
    }
  },

  addCommission(state, payload: CommissionJson) {
    const toStore = {
      ...payload,
      amount: baseAmountToFloat(payload.amount),
    };

    if (!(payload.chain in state.commissions)) {
      state.commissions[payload.chain] = [toStore];
    }

    const index = state.commissions[payload.chain].findIndex(
      (commission) => commission.id === payload.id
    );
    if (index < 0) {
      state.commissions[payload.chain].push(toStore);
    } else {
      state.commissions[payload.chain][index] = toStore;
    }
  },

  addPackageFee(state, payload: PackageFee) {
    const index = state.packageFees.findIndex(
      (packageFee) => packageFee.id === payload.id
    );
    if (index < 0) {
      state.packageFees.push(payload);
    } else {
      state.packageFees[index] = payload;
    }
  },

  addDeposit(state, payload: FeeDepositJson) {
    const toStore = {
      ...payload,
      amount: baseAmountToFloat(payload.amount),
      availableAmount: baseAmountToFloat(payload.availableAmount),
    };

    if (!(payload.chain in state.deposits)) {
      state.deposits[payload.chain] = [toStore];
    }

    const index = state.deposits[payload.chain].findIndex(
      (deposit) =>
        deposit.transactionId === payload.transactionId &&
        deposit.chain &&
        payload.chain
    );
    if (index < 0) {
      state.deposits[payload.chain].push(toStore);
    } else {
      state.deposits[payload.chain][index] = toStore;
    }
  },

  addWithdrawal(state, payload: FeeWithdrawalJson) {
    const toStore = {
      ...payload,
      amount: baseAmountToFloat(payload.amount),
    };

    if (!(payload.chain in state.withdrawals)) {
      state.withdrawals[payload.chain] = [toStore];
    }

    const index = state.withdrawals[payload.chain].findIndex(
      (withdrawal) => withdrawal.id === payload.id
    );
    if (index < 0) {
      state.withdrawals[payload.chain].push(toStore);
    } else {
      state.withdrawals[payload.chain][index] = toStore;
    }
  },
};

const feeActions: ActionTree<FeeState, RootState> = {
  read({ commit }) {
    axios
      .get(apiBasePath + "/fee")
      .then((response) => {
        commit("setFees", response.data);
      })
      .catch((error) => {
        showApiErrorResponse(error);
      });
  },

  pay(
    { rootState },
    payload: {
      account: string;
      amount: string;
      gasLimit: number;
      gasPrice?: string;
    }
  ) {
    return axios
      .post(apiBasePath + "/fee/" + rootState.chain + "/pay", payload)
      .then(() => {
        showSuccess("Fee payment was successfully started");
      })
      .catch((error) => {
        showApiErrorResponse(error);
      });
  },
};

const feeModule: Module<FeeState, RootState> = {
  namespaced: true,
  state: feeState,
  getters: feeGetters,
  mutations: feeMutations,
  actions: feeActions,
};

export default feeModule;
