import { showWarning } from "./notify";
import store from "./store";
import { singleWebsocket, wsBasePath } from "@/config";

function createWebSocket(component?: string) {
  let ws;
  if (component) {
    ws = new WebSocket(
      wsBasePath + "/" + component + "?token=" + store.state.auth.accessToken
    );
  } else {
    ws = new WebSocket(wsBasePath + "?token=" + store.state.auth.accessToken);
  }

  ws.onmessage = function (message) {
    const data = JSON.parse(message.data);

    switch (data.type) {
      case "account":
        store.commit("addAccount", data.value);
        break;
      case "withdrawal":
        store.commit("addWithdrawal", data.value);
        break;
      case "deposit":
        store.commit("addDeposit", data.value);
        break;
      case "snipe":
        store.commit("addSnipe", data.value);
        break;
      case "user":
        store.commit("user", data.value);
        break;
      case "prices":
        store.commit("basic", { key: "prices", value: data.value });
        break;
      case "tokenInfo":
        store.commit("setTokenInfo", data.value);
        break;
      case "commissionHistory":
        store.commit("fees/addCommission", data.value);
        break;
      case "packageFeeHistory":
        store.commit("fees/addPackageFee", data.value);
        break;
      case "feeDeposit":
        store.commit("fees/addDeposit", data.value);
        break;
      case "feeWithdrawalRequest":
        store.commit("fees/addWithdrawal", data.value);
        break;
      default:
        console.error("Unknown type");
    }
  };

  ws.onclose = function () {
    if (component) {
      showWarning(
        `Websocket connection lost for ${component} component. Trying to reconnect...`
      );
    } else {
      showWarning("Websocket connection lost. Trying to reconnect...");
    }
    setTimeout(() => {
      if (store.state.auth.loggedIn) {
        createWebSocket(component);
      }
    }, 5 * 1000);
  };

  return ws;
}

export function createWebSockets() {
  if (singleWebsocket) {
    createWebSocket();
  } else {
    createWebSocket("rest");
    createWebSocket("callback");
    createWebSocket("recurring");
  }
}
